define('tontine-ember/components/js-calendar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    datesObserver: Ember.observer('dates.[]', function () {
      this.get('myCalendar').clearSelected();
      this.get('dates').forEach(date => {
        this.get('myCalendar').select(date);
      });
    }),

    reservedDatesObserver: Ember.observer('reservedDates.[]', function () {
      this.updateReservedDates();
    }),

    updateReservedDates() {
      this.get('myCalendar').clearSelected();
      this.get('reservedDates').forEach(date => {
        this.get('myCalendar').colorfulSelect(date, 'jsCalendar-reserved');
      });
    },

    didInsertElement() {
      // Get the element
      var calendarEl = this.element.querySelector(".calendar");
      // Create the calendar
      var myCalendar = jsCalendar.new({
        target: calendarEl,
        monthFormat: 'month YYYY'
      });
      myCalendar.min(new Date(2018, 10, 1));
      myCalendar.max(new Date(new Date(2033, 12, 31)));
      myCalendar.onDateClick((e, date) => this.onSelectDate(date));
      this.set('myCalendar', myCalendar);
      this.updateReservedDates();
    }
  });
});