define('tontine-ember/components/request-info', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      showConfirmationImage() {
        this.set('showConfirm', true);
      }
    }
  });
});