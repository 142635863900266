define('tontine-ember/routes/admin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel: function () {
      return this.get('session').fetch().catch(function () {});
    },
    actions: {
      signIn: function (provider) {
        this.get('session').open('firebase', { provider }).then(() => {
          this.refresh();
        });
      },
      signOut: function () {
        this.get('session').close();
      }
    }
  });
});