define('tontine-ember/controllers/admin/approve', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function isoToLocalDateObj(isoStr) {
    return new Date(new Date(isoStr).getTime() + new Date(isoStr).getTimezoneOffset() * 60000);
  }

  function toIsoDate(date) {
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString();
    const paddedMonth = month.length < 2 ? "0" + month : month;
    const day = date.getDate().toString();
    const paddedDay = day.length < 2 ? "0" + day : day;

    return `${year}-${paddedMonth}-${paddedDay}`;
  }

  exports.default = Ember.Controller.extend({
    decoratedRequests: Ember.computed('model.requests.[]', 'model.reservations.[]', function () {
      const resHash = {};
      this.get('model.reservations').forEach(reservation => {
        resHash[reservation.day] = true;
      });
      return this.get('model.requests').map(request => {
        return {
          shiftedDays: this.shiftDays(request.dayDates.map(dayDate => dayDate.iso), resHash),
          request
        };
      });
    }),

    shiftDays(dayIsos, resHash) {
      const resHash2 = {};
      return dayIsos.map(dayIso => {
        const dateObj = isoToLocalDateObj(dayIso);
        let newDayIso = dayIso;
        while (resHash[newDayIso] || resHash2[newDayIso]) {
          dateObj.setDate(dateObj.getDate() + 1);
          newDayIso = toIsoDate(dateObj);
        }

        resHash2[newDayIso] = true;

        return {
          original: dayIso,
          isShifted: newDayIso !== dayIso,
          shifted: newDayIso
        };
      });
    },

    actions: {
      approve(dateRequest) {
        dateRequest.shiftedDays.map(shiftedDay => {
          const dateReservation = this.store.createRecord('date-reservation', {
            dateRequest,
            day: shiftedDay.shifted
          });
          dateReservation.save();
        });

        dateRequest.request.set('status', 'APPROVED');
        dateRequest.request.set('reservedDays', dateRequest.shiftedDays.map(day => day.shifted).join(','));
        dateRequest.request.save();
      },

      reject(dateRequest) {
        dateRequest.request.set('status', 'REJECTED');
        dateRequest.request.save();
      }
    }
  });
});