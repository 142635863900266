define('tontine-ember/routes/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model() {
      return Ember.Object.create();
    },

    actions: {
      didTransition() {
        this.store.query('date-reservation', {}).then(reservations => {
          console.log(reservations);
          this.set('controller.model.reservations', reservations);
        });
      }
    }
  });
});