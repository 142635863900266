define('tontine-ember/controllers/reserve', ['exports', 'firebase'], function (exports, _firebase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
          v = c == 'x' ? r : r & 0x3 | 0x8;
      return v.toString(16);
    });
  };

  function toIsoDate(date) {
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString();
    const paddedMonth = month.length < 2 ? "0" + month : month;
    const day = date.getDate().toString();
    const paddedDay = day.length < 2 ? "0" + day : day;

    return `${year}-${paddedMonth}-${paddedDay}`;
  }

  function isoToLocalDateObj(isoStr) {
    return new Date(new Date(isoStr).getTime() + new Date(isoStr).getTimezoneOffset() * 60000);
  }

  exports.default = Ember.Controller.extend({
    formattedDates: Ember.computed('dates.[]', function () {
      return this.get('dates').slice(0).sort((a, b) => a.getTime() - b.getTime()).map(date => {
        return { formatted: date.toLocaleDateString(), naked: date };
      });
    }),

    reservedDates: Ember.computed('model.[]', function () {
      const model = this.get('model');
      return model && model.map(reservation => {
        return isoToLocalDateObj(reservation.get('day'));
      });
    }),

    dollars: Ember.computed('dates.length', function () {
      return this.get('dates.length') * 5;
    }),

    dollarsChecked: false,

    isEmailValid: Ember.computed('emailAddress', function () {
      const email = this.get('emailAddress');

      return email && email.length >= 3 && email.indexOf('@') !== -1;
    }),

    areDatesInvalid: Ember.computed('dates.length', function () {
      return this.get('dates.length') > 20;
    }),

    cantSubmit: Ember.computed('isEmailValid', 'uploadedImageUrl', 'dates.length', 'dollarsChecked', function () {
      return !this.get('isEmailValid') || !this.get('uploadedImageUrl') || this.get('dates.length') < 1 || this.get('dates.length') > 20 || !this.get('dollarsChecked');
    }),

    init() {
      this._super(...arguments);
      this.set('dates', []);
    },

    actions: {
      onSelectDate(date) {
        const dates = this.get('dates');
        const alreadySelectedDate = dates.find(d => d.getTime() === date.getTime());
        if (!alreadySelectedDate) {
          dates.pushObject(date);
        } else {
          dates.removeObject(alreadySelectedDate);
        }
      },

      uploadImage(fileObj) {
        // Create a root reference
        const storageRef = _firebase.default.storage().ref();

        // Create a reference to the image file
        const filename = 'receipts/' + uuidv4() + '/' + uuidv4() + '.png';
        const imageRef = storageRef.child(filename);

        this.set('isUploading', true);
        const uploadTask = imageRef.put(fileObj.blob);

        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on('state_changed', function (snapshot) {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // console.log('Upload is ' + progress + '% done');
          // switch (snapshot.state) {
          //   case firebase.storage.TaskState.PAUSED: // or 'paused'
          //     console.log('Upload is paused');
          //     break;
          //   case firebase.storage.TaskState.RUNNING: // or 'running'
          //     console.log('Upload is running');
          //     break;
          // }
        }, error => {
          this.set('isUploading', false);
          // Handle unsuccessful uploads
        }, () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          this.set('isUploading', false);
          this.set('uploadedImageUrl', uploadTask.snapshot.downloadURL);
          this.set('uploadedImageFilename', filename);
        });
      },

      async submit() {
        var dateRequest = this.store.createRecord('date-request', {
          days: this.get('dates').slice(0).sort((a, b) => a.getTime() - b.getTime()).map(date => toIsoDate(date)).join(','),
          email: this.get('emailAddress'),
          imageFilename: this.get('uploadedImageFilename')
        });
        await dateRequest.save();

        this.transitionToRoute('done');
      }
    }
  });
});