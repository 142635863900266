define('tontine-ember/routes/admin/approve', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function () {
      return Ember.RSVP.hash({
        requests: this.store.query('date-request', { orderBy: 'status', equalTo: null }),
        reservations: this.store.query('date-reservation', { orderBy: 'day' })
      });
    }
  });
});