define('tontine-ember/components/confirm-image', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const BREAKPOINT_1 = 7;
  const BREAKPOINT_2 = 14;

  const toCuteDate = function (date) {
    const cuteMonths = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'];

    return `${cuteMonths[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  };

  exports.default = Ember.Component.extend({
    didInsertElement() {
      const canvas = this.element.querySelector('canvas');

      var ctx = canvas.getContext("2d");
      var img = this.element.querySelector('.confirm-bg');

      // const dates = ['Apr. 31, 2021', 'Jan. 5, 2020', 'Feb. 5, 2019', 'Mar. 5, 2019', 'Apr. 31, 2021', 'Jan. 5, 2020'];
      // const dates = ['Apr. 31, 2021', 'Jan. 5, 2020', 'Feb. 5, 2019', 'Mar. 5, 2019', 'Apr. 31, 2021', 'Jan. 5, 2020', 'Mar. 5, 2019', 'Apr. 31, 2021', 'Jan. 5, 2020', 'Mar. 5, 2019', 'Apr. 31, 2021', 'Jan. 5, 2020'];
      // const dates = ['Feb. 5, 2019', 'Mar. 5, 2019', 'Apr. 31, 2021', 'Jan. 5, 2020', 'Feb. 5, 2019', 'Mar. 5, 2019', 'Apr. 31, 2021', 'Jan. 5, 2020', 'Feb. 5, 2019',  'Mar. 5, 2019', 'Apr. 31, 2021', 'Jan. 5, 2020',  'Mar. 5, 2019', 'Apr. 31, 2021', 'Jan. 5, 2020',  'Mar. 5, 2019', 'Apr. 31, 2021', 'Jan. 5, 2020',  'Mar. 5, 2019', 'Apr. 31, 2021', 'Jan. 5, 2020', 'Mar. 5, 2019', 'Apr. 31, 2021', 'Jan. 5, 2020'];
      // const dates = ['Feb. 5, 2019', 'Mar. 5, 2019', 'Apr. 31, 2021', 'Jan. 5, 2020', 'Feb. 5, 2019', 'Mar. 5, 2019', 'Apr. 31, 2021', 'Jan. 5, 2020', 'Feb. 5, 2019', 'Mar. 5, 2019', 'Apr. 31, 2021', 'Jan. 5, 2020', 'Feb. 5, 2019', 'Mar. 5, 2019', 'Apr. 31, 2021', 'Jan. 5, 2020', 'Feb. 5, 2019',  'Mar. 5, 2019', 'Apr. 31, 2021', 'Jan. 5, 2020',  'Mar. 5, 2019', 'Apr. 31, 2021', 'Jan. 5, 2020',  'Mar. 5, 2019', 'Apr. 31, 2021', 'Jan. 5, 2020',  'Mar. 5, 2019', 'Apr. 31, 2021', 'Jan. 5, 2020', 'Mar. 5, 2019', 'Apr. 31, 2021', 'Jan. 5, 2020'];
      // const dates = ['Feb. 5, 2019'];

      const dates = this.get('dates').map(date => toCuteDate(date.naked));

      const drawConfirmImage = () => {
        ctx.drawImage(img, 0, 0);
        ctx.fillStyle = 'white';

        const start = 290;
        const yRealEstate = 180;

        let curY = start;
        let curX = 75;

        const header = dates.length !== 1 ? '🤞 YOUR DEATH DATES 🤞' : '🤞 YOUR DEATH DATE 🤞';
        ctx.font = `26px Exo`;
        ctx.fillText(header, curX, curY);
        curY += 40;

        const colStart = curY;

        let numCols;

        if (dates.length > BREAKPOINT_2) {
          numCols = 3;
        } else if (dates.length > BREAKPOINT_1) {
          numCols = 2;
        } else {
          numCols = 1;
        }

        const maxPerCol = Math.ceil(dates.length / numCols);
        let lineHeight = yRealEstate / maxPerCol;

        if (numCols === 3 && lineHeight > 17) {
          lineHeight = 17;
        } else if (numCols === 2 && lineHeight > 22) {
          lineHeight = 22;
        } else if (lineHeight > 28) {
          lineHeight = 28;
        }

        const fontSize = lineHeight * 0.9;
        ctx.font = `${fontSize}px Exo`;

        let i = 0;
        let colI = 0;
        dates.forEach(line => {
          ctx.fillText(line, curX, curY);
          curY += lineHeight;
          i += 1;
          colI += 1;

          if (colI > maxPerCol) {
            colI = 0;
            curY = colStart;
            curX += numCols === 2 ? 165 : 110;
          }
        });
      };

      if (img.complete) {
        drawConfirmImage();
      } else {
        img.onload = drawConfirmImage;
      }
    }
  });
});