define('tontine-ember/components/blurred-image', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isBlurred: true,
    actions: {
      unblur() {
        this.set('isBlurred', false);
      }
    }
  });
});