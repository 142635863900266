define('tontine-ember/routes/reserve', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    activate: function () {
      this._super(...arguments);
      window.scrollTo(0, 0);
    },

    model() {
      return this.store.query('date-reservation', { orderBy: 'day' });
    }
  });
});