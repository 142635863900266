define('tontine-ember/models/date-reservation', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    day: _emberData.default.attr('string'),
    dateRequest: _emberData.default.belongsTo('date-request')
  });
});