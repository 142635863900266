define('tontine-ember/models/date-request', ['exports', 'ember-data', 'firebase'], function (exports, _emberData, _firebase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function isoToLocalDateObj(isoStr) {
    return new Date(new Date(isoStr).getTime() + new Date(isoStr).getTimezoneOffset() * 60000);
  }

  function toIsoDate(date) {
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString();
    const paddedMonth = month.length < 2 ? "0" + month : month;
    const day = date.getDate().toString();
    const paddedDay = day.length < 2 ? "0" + day : day;

    return `${year}-${paddedMonth}-${paddedDay}`;
  }

  exports.default = _emberData.default.Model.extend({
    email: _emberData.default.attr('string'),
    days: _emberData.default.attr('string'),
    imageUrl: _emberData.default.attr('string'),
    imageFilename: _emberData.default.attr('string'),
    signedImageUrl: Ember.computed('imageFilename', function () {
      //https://firebasestorage.googleapis.com/v0/b/tontine-ember.appspot.com/o/receipts%2F642efae7-64d0-4580-b82a-98b268bb8cb8%2Fc365cb44-e3c2-4456-8cfb-345a40eeb9a3.png?alt=media&token=5342d8db-703e-475d-8325-20469c9f2fbd
      const filename = this.get('imageFilename');
      // .split('/').lastObject.split('?').firstObject.replace(/%2F/g, '/');
      const storageRef = _firebase.default.storage().ref();
      const imageRef = storageRef.child(filename);

      return _emberData.default.PromiseObject.create({
        promise: new Ember.RSVP.Promise((resolve, reject) => {
          imageRef.getDownloadURL().then(url => {
            resolve({ url });
          });
        })
      });
    }),
    status: _emberData.default.attr('string'),
    reservedDays: _emberData.default.attr('string'),
    dayDates: Ember.computed('days', function () {
      const days = this.get('days');
      if (days) {
        return days.split(',').map(day => {
          const naked = isoToLocalDateObj(day);
          return {
            naked,
            iso: toIsoDate(naked),
            formatted: naked.toLocaleDateString()
          };
        });
      } else {
        return [];
      }
    }),
    reservedDayDates: Ember.computed('reservedDays', function () {
      const days = this.get('reservedDays');
      if (days) {
        return days.split(',').map(day => {
          const naked = isoToLocalDateObj(day);
          return {
            naked,
            iso: toIsoDate(naked),
            formatted: naked.toLocaleDateString()
          };
        });
      } else {
        return [];
      }
    })
  });
});