define('tontine-ember/router', ['exports', 'tontine-ember/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('admin', function () {
      this.route('approve');
      this.route('confirm');
      this.route('all');
    });
    this.route('reserve');
    this.route('done');
  });

  exports.default = Router;
});