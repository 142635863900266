define('tontine-ember/models/article', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  const { Model, attr } = _emberData.default;

  exports.default = Model.extend({
    title: attr('string')
  });
});